import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { BaseItem, SearchOptions, SearchResults } from '@guillotinaweb/grange-core'
import { Grange } from 'grange'
import { Subscription } from 'rxjs'

interface OperatorValue {
  title: string
}
interface Operator {
  description: string
  operation: string
  title: string
  widget: string
}
interface Index {
  description: string
  enabled: true
  group: 'Metadata' | 'Text' | 'Dates' | 'Orders'
  operations: Array<string>
  operators: { [operator: string]: Operator }
  sortable: boolean
  title: string
  values: { [value: string]: OperatorValue }
  vocabulary: string
}
interface QuerystringResult {
  '@id': string
  indexes: {
    [index: string]: Index
  }
  sortable_indexes: {
    [index: string]: Index
  }
}

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit, OnDestroy {
  protected FILTERS_METADATA_FIELDS = ['created', 'Creator']
  protected FILTERS_LIST = ['search', 'linebuilder', 'state', 'created', 'Creator']

  @Input() creatorTitle = 'Creator'
  @Input() initialSort = null
  @Input() initialSortDir = null
  @Input() excludeColumns = []
  @Input() layout_class_mode = 'search-layout-columns'
  @Input() public expandedComponent: string | null = null

  private cleanVal = false
  @Input() get clean(): boolean {
    return this.cleanVal
  }
  set clean(val: boolean) {
    this.cleanVal = val
    this.updateSearch()
  }

  private checkboxVal = false
  @Input() get checkbox(): boolean {
    return this.checkboxVal
  }
  set checkbox(val: boolean) {
    this.checkboxVal = val
    this.updateSearch()
  }

  private showAllFiltersVal = false
  @Input() get showAllFilters(): boolean {
    return this.showAllFiltersVal
  }
  set showAllFilters(val: boolean) {
    this.showAllFiltersVal = val
    this.updateSearch()
  }
  protected filtersVal: Array<string> = []
  @Input() get filters(): Array<string> {
    return this.filtersVal
  }
  set filters(val: Array<string>) {
    this.filtersVal = val
    this.updateSearch()
  }
  private pathVal: string
  @Input() get path(): string {
    return this.pathVal
  }
  set path(val: string) {
    this.pathVal = val
    this.updateSearch()
  }
  private optionsVal: SearchOptions
  @Input() get options(): SearchOptions {
    return this.optionsVal
  }
  set options(val: SearchOptions) {
    this.optionsVal = val
    this.updateSearch()
  }
  private queryVal: { [key: string]: any }
  @Input() get query(): { [key: string]: any } {
    return this.queryVal
  }
  set query(val: { [key: string]: any }) {
    this.queryVal = val
    this.updateSearch()
  }

  @Input() action = ''
  @Input() public nameTitle = 'Name'
  @Input() public nameTitleValue = 'Name'
  public result: SearchResults

  public search: string
  public currentPath: string

  public created: FormGroup = this.fb.group({
    start: ['', Validators.required],
    end: ['', Validators.required],
  })

  public modified: FormGroup = this.fb.group({
    start: ['', Validators.required],
    end: ['', Validators.required],
  })

  public delivery: FormGroup = this.fb.group({
    start: ['', Validators.required],
    end: ['', Validators.required],
  })
  public confirmedDelivery: FormGroup = this.fb.group({
    start: ['', Validators.required],
    end: ['', Validators.required],
  })
  public implementationDate: FormGroup = this.fb.group({
    start: ['', Validators.required],
    end: ['', Validators.required],
  })
  public lineBuilder: FormGroup = this.fb.group({
    lineBuilder: [''],
  })

  public creator: FormGroup = this.fb.group({
    creator: [''],
  })

  public reviewState: FormGroup = this.fb.group({
    reviewState: [''],
  })

  public subs: Array<Subscription> = []
  public get showSearch(): boolean {
    return this.filters.includes('search') || this.showAllFilters
  }
  public get showCreated(): boolean {
    return this.filters.includes('created') || this.showAllFilters
  }
  public get showModified(): boolean {
    return this.filters.includes('modified') || this.showAllFilters
  }
  public get showDelivery(): boolean {
    return this.filters.includes('delivery') || this.showAllFilters
  }
  public get showLineBuilder(): boolean {
    return (
      this.allFiltersForms.includes(this.lineBuilder) &&
      (this.filters.includes('linebuilder') || this.showAllFilters)
    )
  }
  public get showCreator(): boolean {
    return (
      this.allFiltersForms.includes(this.creator) &&
      (this.filters.includes('Creator') || this.showAllFilters)
    )
  }
  public get showImplementationDated(): boolean {
    return (
      this.allFiltersForms.includes(this.implementationDate) &&
      (this.filters.includes('implementation_date') || this.showAllFilters)
    )
  }

  public get showReviewState(): boolean {
    return (
      this.allFiltersForms.includes(this.reviewState) &&
      (this.filters.includes('state') || this.showAllFilters)
    )
  }
  private allFiltersForms: Array<FormGroup> = [
    this.created,
    this.modified,
    this.delivery,
    this.confirmedDelivery,
    this.creator,
    this.implementationDate,
  ]

  private allDateFiltersName = ['created', 'delivery', 'implementationDate']
  public isLoadingData = true

  public sortTypes: Array<{ value: string; name: string }> = []

  private updateSearchSubs: Subscription
  private updateSearchTimer: any

  public lineBuilderOptions: Array<{ val: string; title: string }> = []
  private reviewStateOptions: Array<{ val: string; title: string }> = []
  public get reviewStateOptionsFiltred(): Array<{ val: string; title: string }> {
    return this.reviewStateOptions
      .filter((item) => {
        return this.items.map((mapItem) => mapItem.review_state).includes(item.val)
      })
      .sort((a, b) => (a.title > b.title ? 1 : -1))
  }

  public get creatorsFiltered(): Array<string> {
    return this.creator.get('creator').value || []
  }
  public get reviewStateFiltered(): Array<string> {
    return this.reviewState.get('reviewState').value || []
  }

  public get dateFiltered() {
    const result = {}
    this.allDateFiltersName.forEach((name) => {
      if (this[name]) {
        result[name] = {}
        result[name]['start'] = this[name].get('start').value
        result[name]['end'] = this[name].get('end').value
      }
    })
    return result
  }

  public get creators(): Array<string> {
    const creatorSet = new Set<string>()
    this.items.map((mapItem) => {
      if (mapItem.Creator) {
        creatorSet.add(mapItem.Creator)
      }
    })
    return Array.from(creatorSet)
  }
  public get showFilters(): boolean {
    return (
      this.showAllFilters ||
      this.filters.some((filter) => {
        return this.FILTERS_LIST.includes(filter)
      })
    )
  }
  public items: Array<BaseItem | any> = []
  public itemsFiltered: Array<BaseItem | any> = []
  constructor(private grange: Grange, private fb: FormBuilder) {}

  ngOnInit() {
    this.loadFilters()
    this.grange.traverser.target.subscribe((res) => {
      this.currentPath = res.path
    })
    this.allFiltersForms.map((filter: FormGroup) => {
      this.subs.push(
        filter.valueChanges.subscribe(() => {
          if (filter.valid && filter === this.creator) {
            const value = this.creator.get('creator').value
            localStorage.setItem(`${this.currentPath}-creator`, value)
          } else if (filter.valid) {
            this.allDateFiltersName.forEach((name) => {
              if (this[name] === filter) {
                const startDate = new Date(this[name].get('start').value).toISOString()
                const endDate = new Date(this[name].get('end').value).toISOString()

                localStorage.setItem(`${this.currentPath}-${name}-start`, startDate)
                localStorage.setItem(`${this.currentPath}-${name}-end`, endDate)
              }
            })
          }
          this.updateItemsFiltered()
        })
      )
    })
  }

  ngOnDestroy() {
    this.subs.map((sub: Subscription) => {
      sub.unsubscribe()
    })
  }

  public hasAnyFilterActive = () => {
    if (this.search) return true
    if (this.creatorsFiltered.length > 0) return true
    if (this.reviewStateFiltered.length > 0) return true
    return Object.keys(this.dateFiltered).some((key) => {
      return (
        this.dateFiltered[key]['start'] &&
        this.dateFiltered[key]['start'] !== '' &&
        this.dateFiltered[key]['end'] &&
        this.dateFiltered[key]['end'] !== ''
      )
    })
  }

  private updateItemsFiltered() {
    this.itemsFiltered = this.items.filter((item) => {
      const searchTitel = item.title.toLowerCase().includes((this.search || '').toLowerCase())
      let searchNumber = false
      if (item.number) {
        searchNumber = String(item.number)
          .toLowerCase()
          .includes((this.search || '').toLowerCase())
      }

      if (!(searchTitel || searchNumber)) return false

      const createdBy =
        this.creatorsFiltered.length === 0 || this.creatorsFiltered.includes(item.Creator)
      if (!createdBy) return false

      const isInReviewState =
        this.reviewStateFiltered.length === 0 ||
        this.reviewStateFiltered.includes(item.review_state)
      if (!isInReviewState) return false

      let isInDateFiltered = true
      Object.keys(this.dateFiltered).forEach((key) => {
        if (
          this.dateFiltered[key]['start'] &&
          this.dateFiltered[key]['start'] !== '' &&
          this.dateFiltered[key]['end'] &&
          this.dateFiltered[key]['end'] !== ''
        ) {
          const startDate = this.dateFiltered[key]['start']
          const endtDate = this.dateFiltered[key]['end']
          const itemDate = new Date(item[key])

          isInDateFiltered =
            isInDateFiltered &&
            new Date(startDate).getTime() <= itemDate.getTime() &&
            new Date(endtDate).getTime() >= itemDate.getTime()
        }
      })
      return isInDateFiltered
    })
  }

  private updateSearch() {
    clearTimeout(this.updateSearchTimer)
    this.updateSearchTimer = setTimeout(() => {
      this.isLoadingData = true
      if (!this.query) {
        return
      }
      if (this.updateSearchSubs) {
        this.updateSearchSubs.unsubscribe()
      }

      const query = Object.assign({ sort_on: 'getId' }, this.query)
      const options = Object.assign({}, this.options)
      if (this.showAllFilters || this.filters.length > 0) {
        const metadataFieldsSet = options.metadata_fields ? options.metadata_fields : []
        metadataFieldsSet.push(...this.FILTERS_METADATA_FIELDS)
        // metadataFieldsSet.push('Creator');
        options.metadata_fields = Array.from(new Set(metadataFieldsSet))
        // options.fullobjects = true;
        this.checkFilters()
      }
      this.updateSearchSubs = this.grange.core.resource
        .find(query, this.path, options)
        .subscribe((result: SearchResults) => {
          this.result = result
          this.items = []
          this.recursiveSearch(result)
        })
    }, 300)
  }

  private recursiveSearch(result: SearchResults) {
    this.items = [...this.items, ...result.items]
    if (result.batching && result.batching.next) {
      this.updateSearchSubs = this.grange.core.resource
        .get(result.batching.next)
        .subscribe((newResult: SearchResults) => {
          this.recursiveSearch(newResult)
        })
    } else {
      this.updateItemsFiltered()
      this.isLoadingData = false
    }
  }

  public loadFilters() {
    if (this.showAllFilters || this.filters.length > 0) {
      this.grange.core.cache.get('/@querystring').subscribe((result: QuerystringResult) => {
        if (!!result.indexes.LineBuilder) {
          this.allFiltersForms.push(this.lineBuilder)
          const newOptions = []
          Object.keys(result.indexes.LineBuilder.values).map((key: string) => {
            newOptions.push({ val: key, title: result.indexes.LineBuilder.values[key].title })
          })
          this.lineBuilderOptions = newOptions
          this.subs.push(
            this.lineBuilder.valueChanges.subscribe(() => {
              if (this.lineBuilder.valid) {
                const value = this.reviewState.get('lineBuilder').value
                localStorage.setItem(`${this.currentPath}-lineBuilder`, value)
                this.updateItemsFiltered()
              }
            })
          )
        }
        if (!!result.indexes.review_state) {
          this.allFiltersForms.push(this.reviewState)
          const newOptions = []
          Object.keys(result.indexes.review_state.values).map((key: string) => {
            newOptions.push({
              val: key,
              title: result.indexes.review_state.values[key].title.replace(/\[[\w-]*\]/, ''),
            })
          })
          this.reviewStateOptions = newOptions
          this.subs.push(
            this.reviewState.valueChanges.subscribe(() => {
              if (this.reviewState.valid) {
                const value = this.reviewState.get('reviewState').value
                localStorage.setItem(`${this.currentPath}-reviewState`, value)
                this.updateItemsFiltered()
              }
            })
          )
        }

        if (!!result.sortable_indexes) {
          const sortTypes: Array<{ value: string; name: string }> = []
          Object.keys(result.sortable_indexes).map((index: string) => {
            if (result.sortable_indexes[index].enabled) {
              sortTypes.push({ value: index, name: result.sortable_indexes[index].title })
            }
          })
          this.sortTypes = sortTypes
        }
      })
    }
  }

  private checkFilters() {
    this.allDateFiltersName.forEach((name) => {
      const startDateLS = localStorage.getItem(`${this.currentPath}-${name}-start`)
      const endDateLS = localStorage.getItem(`${this.currentPath}-${name}-end`)
      if (startDateLS && endDateLS) {
        this[name].setValue({ start: startDateLS, end: endDateLS })
      }
    })

    const currentSearchLS = localStorage.getItem(`${this.currentPath}-search`)
    if (currentSearchLS) {
      this.search = currentSearchLS
    }

    const creatorLS = localStorage.getItem(`${this.currentPath}-creator`)
    if (creatorLS) {
      this.creator.setValue({ creator: creatorLS.split(',') })
    }

    const reviewStateLS = localStorage.getItem(`${this.currentPath}-reviewState`)
    if (reviewStateLS) {
      this.reviewState.setValue({ reviewState: reviewStateLS.split(',') })
    }
  }

  public onChangeFilter(value) {
    this.search = value
    localStorage.setItem(`${this.currentPath}-search`, value)
    this.updateItemsFiltered()
  }

  public clearFilters() {
    localStorage.removeItem(`${this.currentPath}-search`)
    localStorage.removeItem(`${this.currentPath}-reviewState`)
    localStorage.removeItem(`${this.currentPath}-creator`)
    this.allDateFiltersName.forEach((name) => {
      localStorage.removeItem(`${this.currentPath}-${name}-start`)
      localStorage.removeItem(`${this.currentPath}-${name}-end`)
      this[name].setValue({ start: null, end: null })
    })
    this.reviewState.setValue({ reviewState: [] })
    this.creator.setValue({ creator: [] })
    this.search = ''

    localStorage.removeItem(`${this.currentPath}-page-index`)
    localStorage.removeItem(`${this.currentPath}-page-size`)
    localStorage.removeItem(`${this.currentPath}-sort-active`)
    localStorage.removeItem(`${this.currentPath}-sort-direction`)
    this.updateItemsFiltered()
  }
}
