import { Component, OnInit } from '@angular/core'
import { Resource, WorkflowInformation } from '@guillotinaweb/grange-core'
import { Grange } from 'grange'
import { BaseComponent } from '../base'

@Component({
  selector: 'app-workflow',
  templateUrl: './workflow.component.html',
  styleUrls: ['./workflow.component.scss'],
})
export class WorkflowComponent extends BaseComponent implements OnInit {
  public workflow: WorkflowInformation
  public showWorkflow: boolean
  private path: string
  private date: Date
  public get currentState(): string {
    if (!this.workflow || !this.workflow.history || !this.workflow.history.length) {
      return ''
    }
    return this.workflow.history[this.workflow.history.length - 1].title
  }
  constructor(public grange: Grange) {
    super(grange)
  }

  ngOnInit() {
    this.context.subscribe((context: Resource) => {
      this.showWorkflow = [
        'abb.orders.pack',
        'abb.orders.changerequest',
        'abb.orders.order',
        'abb.orders.project',
      ].includes(context['@type'])
      this.date = context['date']
    })
    this.contextPath.subscribe((path: string) => {
      this.path = path
      this.loadWorkflow()
    })
  }

  public transationTo(id: string) {
    const transitionSplit = id.split('/')
    const transition = transitionSplit[transitionSplit.length - 1]
    if (transition === 'approved-by-client' && this.date && new Date(this.date) < new Date()) {
      alert('Valid response date outdated, contact ABB')
    } else {
      this.grange.core.resource.transition(this.path, transition).subscribe((res) => {
        window.location.reload()
      })
    }
  }

  private loadWorkflow() {
    if (!this.path) {
      return
    }
    this.workflow = undefined
    this.grange.core.resource.workflow(this.path).subscribe((workflow) => {
      this.workflow = workflow
    })
  }
}
