import { Pipe, PipeTransform } from '@angular/core'

export const STATE_TITLES = {
  '1-1-submitted-to-the-client': '2- Submitted to the client',
  '4-in-progress': '4- In progress',
  'acknowledgement-accepted': '5- Order accepted',
  approved: '5- Approved',
  'cancelled-cr': 'Cancelled CR',
  'delivery-approved': '8- Invoiced',
  'asend-to-acknowledgement': 'Send to In progress',
  'asend-to-abb': 'Send to ABB',
  'send-to-invoiced': 'Send back to invoice',
  'asend-to-invoicing-process': 'Payment received',
  'reject-to-client': 'Send back to at client',
  'asend-to-client': 'Send to Client',
  'send-back-to-abb': 'Send back to at ABB',
  'send-back-one-step': 'Send back to In progress',
  'send-back-to-acknowleggement': 'Send back to order accepted',
  'send-to-order-waiting-to-be-delivered': 'Send back to delivery accepted by client',
  'asend-to-production-delivery': 'Delivered',
  'send-back-to-production': 'Send back to delivered',
  'zcancel-cr': 'Cancelled CR',
  'zcancel-cr-abb': 'Cancelled CR ABB',
  'zcancel-cr-client': 'Cancelled CR Client',
  'zcancel-cr-client-4': 'Cancelled CR Client',
  'zcancel-cr-abb-3': 'Cancelled CR ABB',
  reject: 'Send back to draft',
  draft: 'Draft',
  'draft-pre-order': '1- Draft',
  external: 'Externally visible',
  finalized: '7- Finalized',
  'in-execution': '6- In execution',
  internal: 'Internal draft',
  'internally-published': 'Published',
  internally_published: 'Published',
  'agoods-recieved': 'Invoiced',
  'adeliver-order': 'Delivery accepted by client',
  'accept-order': 'Accept order',
  invoicing: '9- Payment received',
  'linebuilder-draft': '1- Linebuilder draft',
  'order-accepted': '2- At Client',
  'order-in-production': '6- Delivered',
  'order-waiting-to-be-delivered': '7- Delivery accepted by client',
  pending: 'Pending',
  private: 'Private',
  published: 'Published',
  rejected: 'Closed',
  'sent-to-abb': '3- At ABB',
  'sent-to-submission': '3- Submitted to ABB',
  'submitted-to-abb': '3- Submitted to ABB',
  'under-work': '1- Under Work',
  visible: 'Public draft',
  'waiting-to-be-approved': '4- Waiting to be approved by ABB',
  'waiting-to-be-approved-by-client': '4- Waiting to be approved by Client',
  'waiting-to-be-approved-client': '5- Waiting to be approved by client',
  'asend-to-client-approval': 'Sent to client for approval',
  'send-back-to-submitted-to-abb': 'Sent back to submitted to abb',
  'asend-to-submission-abb': 'Submitted to abb',
  'asend-to-client-for-submission': 'Submitted to client',
  afinalize: 'Finalized',
  'aexecute-cr': 'Executed',
  'approved-by-client': 'Approved by client',
}

@Pipe({
  name: 'state',
})
export class StatePipe implements PipeTransform {
  transform(state: string): string {
    if (STATE_TITLES[state]) {
      return STATE_TITLES[state]
    }
    return state
  }
}
